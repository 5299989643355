import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  padding: ${({ pad }) => pad};
`;

export const StyledPrice = styled.div`
  color: ${({ theme }) => theme.colors.pink};
  justify-items: flex-end;
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-family: 'Barlow';
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    font-size: 1.25rem;
  }
`;

export const StyledDate = styled.div`
  font-size: 1rem;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.lightGray};
  margin-top: 0.15rem;
`;

StyledDate.defaultProps = {};
