import React from 'react';

/* ========== Icons ========== */

/* ========== Layouts ========== */

/* ========== Components ========== */
import Form from 'structures/atoms/Form';
import Input from 'structures/atoms/Input';
import Button from 'structures/atoms/Button';

function Deposit() {
  return (
    <Form>
      <Input type={'text'} label={'Country'} />
      <Input type={'number'} label={'Amount'} />
      <Button margin={'2rem auto 0'}>Deposit Fund</Button>
    </Form>
  );
}

export default Deposit;
