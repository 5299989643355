import React from 'react';
import Button from 'structures/atoms/Button';
import Input from 'structures/atoms/Input';
import { Form } from 'utils/Abstraction';

function SignupForm() {
  return (
    <Form name={'SignupForm'}>
      <Input type={'email'} label={'Email address'} required={'required'} />
      <Input type={'password'} label={'Create Password'} />
      <Input type={'password'} label={'Confirm Password'} />
      <Button style={{ marginTop: '1rem' }} onClick={() => setisLoggedIn(true)}>
        Signup
      </Button>
    </Form>
  );
}

export default SignupForm;
