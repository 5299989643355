import styled from 'styled-components';

export const StyledAvatar = styled.div`
  position: relative;

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  background-color: transparent;
  border-radius: 50%;

  overflow: hidden;
  flex-shrink: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;

StyledAvatar.defaultProps = {
  width: '2.65rem',
  height: '2.65rem'
};
