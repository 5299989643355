import { Container } from 'structures/layouts/Styles/Container.style';
import React from 'react';

function QAComponent() {
  return (
    <Container>
      <h3 className="SidePane__title">Q&AComponent</h3>
    </Container>
  );
}

export default QAComponent;
