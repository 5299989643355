import React from 'react';

import { Avatar } from 'structures/atoms/Avatar';
import Picture from 'structures/atoms/Picture';
import { Flex } from 'structures/utilities/Flex.style';

/* ========== Atoms ========== */
import PictureImg from 'assets/img/UP-BIG.png';
import AvatarImg from 'assets/img/Avatar.png';

/* ========== Styles ========== */
import { ProfileTag, StyledEventPreview } from '../styles/EventId.style';
import { Stack } from 'structures/utilities/Stack.style';
import Button from 'structures/atoms/Button';

export default function EventPreview() {
  /* ========== New Component ========== */
  return (
    <StyledEventPreview>
      <Picture
        className="event__picture"
        height={'280px'}
        pictureSrc={PictureImg}
        oFit={'cover'}
      >
        <button className="absolute">💖 Add to Wishlist</button>
      </Picture>

      <Stack gap=".5rem" padding="20px" className="event__body">
        <div className="event__title">
          <h3>The All New Range 2020 Launch Party</h3>
        </div>

        <Flex justifyContent="space-between" alignItem="center">
          <Flex gap=".5rem" alignItem="center">
            <Avatar avatarImg={AvatarImg} />
            <Flex
              direction="column"
              style={{ lineHeight: 1.2, fontSize: '1.25rem' }}
            >
              <p>Theresa Webb</p>
              <small style={{ color: 'gray' }}>Creator</small>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            alignItem="flex-end"
            style={{
              lineHeight: 1.2,
              fontSize: '1.25rem'
            }}
          >
            <div className="event__date" style={{ color: '#ff0e83' }}>
              12 April 2020
            </div>
            <div
              className="event__time"
              style={{
                color: '#5458f7',
                fontFamily: 'Barlow',
                fontSize: '1.423rem',
                letterSpacing: '1px',
                fontWeight: '100'
              }}
            >
              10:47AM
            </div>
          </Flex>
        </Flex>

        <button className="event__link">Copy Event Link</button>

        <Stack>
          <p>Guests</p>
          <Flex justifyContent="flex-start" gap=".5rem">
            <ProfileTag>
              <Avatar
                width={'1.5rem'}
                height={'1.5rem'}
                avatarImg={AvatarImg}
              />
              <p>Jonathan</p>
            </ProfileTag>
            <ProfileTag>
              <Avatar
                width={'1.5rem'}
                height={'1.5rem'}
                avatarImg={AvatarImg}
              />
              <p>Jonathan</p>
            </ProfileTag>
          </Flex>
        </Stack>
        <Flex justifyContent="flex-start" gap=".5rem">
          <div className="event__tag">Party</div>
          <div className="event__tag">Party</div>
        </Flex>

        <Stack gap=".5rem" className="event__description">
          <h3>Description</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
            esse inventore pariatur nobis asperiores numquam nulla magnam,
            delectus nisi eos. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Aut, expedita? Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Obcaecati, ad facere rem accusamus
            rerum, doloremque totam vitae, perferendis quos esse maiores
            laboriosam architecto autem sunt explicabo. Itaque iusto maxime
            quidem doloremque necessitatibus velit voluptate voluptatum
            molestiae, sunt alias quia consectetur inventore, rerum nesciunt
            iste eos aut libero nostrum eveniet sequi! Inventore, iste excepturi
            fuga dolorem, eaque, neque suscipit praesentium reprehenderit
            ratione esse quod distinctio perspiciatis qui ex aliquid delectus
            soluta eum nam!
          </p>
        </Stack>

        <Stack>
          <p>Sponsors</p>
          <Flex gap=".5rem">
            <ProfileTag>
              <Avatar
                width={'1.5rem'}
                height={'1.5rem'}
                avatarImg={AvatarImg}
              />
              <p>Jonathan</p>
            </ProfileTag>
            <ProfileTag>
              <Avatar
                width={'1.5rem'}
                height={'1.5rem'}
                avatarImg={AvatarImg}
              />
              <p>Jonathan</p>
            </ProfileTag>
          </Flex>
        </Stack>

        <Flex justifyContent="space-between">
          <p></p>
          <p>13 Million+ Going</p>
        </Flex>

        <div className="event__action">
          <Button width={'80%'}>Buy for $17.99</Button>
        </div>
      </Stack>
    </StyledEventPreview>
  );
}
