import React from 'react';
import styled from 'styled-components';

function Input({ label, type, required, ref, onClick, onChange }) {
  return (
    <StyledInput onChange={onChange}>
      <label>{label}</label>
      <input type={type} required={required}></input>
    </StyledInput>
  );
}

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: max-content;
  gap: 0.15rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  input {
    display: block;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.purple};
    padding: 1rem 1rem;
    font-size: 1.2rem;
    border-radius: 0.35rem;
  }
`;

export default Input;
