import React from 'react';
import { Link } from 'react-router-dom';

/* ========== Style ========== */
import { StyledLive } from './Styles/Live.style';

export default function Live() {
  /* ========== New Component ========== */
  return (
    <StyledLive>
      <div className="buttons-container">
        <button>
          <Link to={'#'}>
            Create Event <span>Schedule an audio or video event for later</span>
          </Link>
        </button>
        <button>
          Go live <span>Create an instant audio or video event </span>
        </button>
        <button>
          Publish on Play <span>Publish video or ausio content instantly</span>
        </button>
      </div>
    </StyledLive>
  );
}
