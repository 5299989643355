import React from 'react';
import TextTruncate from 'react-text-truncate';

/*========== Components ========== */
import { StyledTicketCard } from './Styles/TicketCard.style';
import { StyledPrice } from 'structures/utilities/Card.style';

/*========== Style ========== */
import { Flex } from 'structures/utilities/Flex.style';
import { Avatar } from 'structures/atoms/Avatar';
import LineDivider from 'structures/atoms/LineDivider';

function TicketCard({ description, price, creatorAvatar, onClick }) {
  /* ====== New Component ====== */
  return (
    <StyledTicketCard onClick={onClick} style={{ padding: '1rem 0.5rem 0' }}>
      <Avatar height={'1.65rem'} width={'1.65rem'} avatarImg={creatorAvatar} />
      <Flex justifyContent="center" gap=".5rem"></Flex>
      <>
        <div style={{ paddingBottom: '1rem', borderBottom: '1px solid gray' }}>
          <TextTruncate
            line={3}
            element="p"
            truncateText="…"
            text={description}
            textTruncateChild={''}
            style={{ fontSize: '1rem' }}
          />
        </div>
        <p className="date" style={{ color: 'gray' }}>
          1 Jun, 2022
        </p>
        <Flex justifyContent="space-between">
          <StyledPrice style={{ fontFamily: 'Brown', fontSize: '1rem' }}>
            {price}
          </StyledPrice>
          <div className="time" style={{ color: 'gray' }}>
            2:30pm
          </div>
        </Flex>
      </>
    </StyledTicketCard>
  );
}

export default TicketCard;
