import React from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 10000;

  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.colors.darkBlack};
  opacity: 1;
  visibility: visible;
  transition: 0.3s;

  > * {
    opacity: 1;
  }

  &.stop-loading {
    opacity: 0;
    visibility: hidden;
    z-index: -100;

    > * {
      opacity: 0;
    }
  }
`;
export default Loading;
