import React from 'react';
import { ThemeProvider } from 'styled-components';

/* ========== Theme ========== */
import { theme } from 'utils/Themes';

/* ========== Styles ========== */
import GlobalStyles from './Styles/Global.style';
import { StyledAppLayout } from './Styles/AppLayout.style';

export default function AppLayout({ children }) {
  /* ========== New Component ========== */
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledAppLayout>{children}</StyledAppLayout>
    </ThemeProvider>
  );
}
