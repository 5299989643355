import React, { useContext, useEffect } from 'react';

/* ========== Components ========== */
import { ReactComponent as CloseIcon } from 'assets/icons/svg/close-icon.svg';

/* ========== Styles ========== */
import { StyledSidepane, StyledSidePaneCloser } from './styles/Index.style';

/* ========== Import Context ========== */
import Loading from 'structures/atoms/Loading';
import LayoutContext from 'contexts/LayoutContext';

const SidePane = ({ component, className }) => {
  /* ========== useContext ========== */
  const { ComponentIsLoading, setPreviewWithOverlay } =
    useContext(LayoutContext);

  /* ========== New Component ========== */
  return (
    <StyledSidepane component={component} className={className}>
      <StyledSidePaneCloser onClick={() => setPreviewWithOverlay('', false)}>
        <div className="svgContainer">
          <CloseIcon fill="green" stroke="white" />
        </div>
      </StyledSidePaneCloser>
      <Loading className={ComponentIsLoading ? '' : 'stop-loading'}>
        <img src="assets/img/loading.gif" height={'100px'} width={'100px'} />
      </Loading>
      {component}
    </StyledSidepane>
  );
};

export default SidePane;
