import React from 'react';
import styled from 'styled-components';

function Form({ children, name, border, padding, onSubmit }) {
  return (
    <StyledForm
      name={name}
      border={border}
      padding={padding}
      onSubmit={onSubmit}
    >
      {children}
    </StyledForm>
  );
}

const StyledForm = styled.form`
  width: 100%;
  display: grid;
  height: max-content;
  gap: 0.5rem;
  font-family: 'Brown';
  font-size: 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: 0.65rem;
`;

export default Form;
