import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import adapter from 'webrtc-adapter';

/* ========== Routes ========== */
import { routePaths } from 'utils/Routes';
import ProtectedRoute from 'utils/ProtectedRoute';

/* ========== Contexts and Providers ========== */
import { DataProvider } from 'providers/DataProvider';
import LayoutContext from 'contexts/LayoutContext';
import DataContext from 'contexts/DataContext';
/* ========== Components ========== */
import Aside from 'structures/layouts/Aside';
import Header from 'structures/layouts/Header';
import SidePane from 'structures/components/SidePane/Index';

/* ========== Layouts ========== */
import AppLayout from 'structures/layouts/AppLayout';
import { StyledLayout } from 'structures/layouts/Styles/Layout.style';
import { Main } from 'structures/layouts/Styles/Container.style';
import Auth from 'structures/pages/Auth';
import { Overlay } from 'structures/layouts/Styles/Overlay.style';
import Loading from 'structures/atoms/Loading';
import AuthContext from 'providers/AuthProvider';

export default function App() {
  const [sidePane, setSidePane] = useState(null);
  const [previewComponent, setPreviewComponent] = useState('');
  const [ComponentIsLoading, setComponentIsLoading] = useState('');
  const [rendered, setRendered] = useState(false);
  const [overlay, setOverlay] = useState(null);
  const [offScroll, setOffScroll] = useState(null);

  const { auth } = useContext(AuthContext);
  function setPreviewWithOverlay(elem, status) {
    setOverlay(status);
    setSidePane(status);
    setOffScroll(status);
    setComponentIsLoading(status);

    setTimeout(() => {
      setPreviewComponent(elem);
      setComponentIsLoading(false);
    }, 700);
  }

  function setPreview(elem, status) {
    setSidePane(status);
    setPreviewComponent(elem);
  }

  useLayoutEffect(() => {
    const loadData = async () => {
      setComponentIsLoading(true);

      await new Promise((r) => setTimeout(r, 4000));
      setRendered((rendered) => !rendered);
      setComponentIsLoading((ComponentIsLoading) => !ComponentIsLoading);
    };

    loadData();
  }, []);

  adapter.browserDetails.version;

  /* ========== New Component ========== */
  return (
    <LayoutContext.Provider
      value={{
        sidePane,
        previewComponent,
        setPreview,
        setPreviewWithOverlay,
        setSidePane,
        setPreviewComponent,
        setComponentIsLoading,
        offScroll,
        overlay,
        ComponentIsLoading
      }}
    >
      <DataProvider>
        <AppLayout>
          {!rendered && (
            <Loading className={ComponentIsLoading ? '' : 'stop-loading'}>
              <img
                src="assets/img/loading.gif"
                height={'100px'}
                width={'100px'}
              />
            </Loading>
          )}
          <Router>
            {/* ========== Header ========== */}
            <Header />

            <StyledLayout>
              {/* ========== Aside ========== */}
              <Aside />

              <Main className={offScroll ? 'offScroll' : ''}>
                {/* ========== Overlay ========== */}
                {overlay && (
                  <Overlay onClick={() => setPreviewWithOverlay('', false)} />
                )}

                {/* ========== Routes ========== */}
                <Routes>
                  {!auth && <Route path="/auth" element={<Auth />} />}

                  <Route element={<ProtectedRoute />}>
                    {routePaths.map(({ name, path, component }) => (
                      <Route
                        key={name}
                        exact
                        path={path}
                        element={component}
                      ></Route>
                    ))}
                  </Route>

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Main>
              {/* ========== SidePane ========== */}
              {sidePane && <SidePane component={previewComponent} />}
            </StyledLayout>
          </Router>
        </AppLayout>
      </DataProvider>
    </LayoutContext.Provider>
  );
}
