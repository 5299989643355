import React from 'react';
import { Container } from 'structures/layouts/Styles/Container.style';

export default function Play() {
  /* ========== New Component ========== */
  return (
    <Container>
      <h2>Play</h2>
    </Container>
  );
}
