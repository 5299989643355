import React from 'react';

/* ========== Icons ========== */
import { BiArrowBack } from 'react-icons/bi';

/* ========== Layouts ========== */
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import { Container } from 'structures/layouts/Styles/Container.style';

function TransactionHistory() {
  return (
    <Container name="transaction-history">
      <Flex>
        <div className="back">
          <BiArrowBack />
        </div>
        <h4>Transaction History</h4>
      </Flex>
      <h3>podoppo</h3>
    </Container>
  );
}

export default TransactionHistory;
