import React from 'react';
import styled from 'styled-components';

function Button({
  bgColor,
  color,
  size,
  width,
  margin,
  outline,
  type,
  onClick,
  gap,
  children
}) {
  return outline ? (
    <StyledButtonOutline
      bgColor={bgColor}
      labelColor={color}
      size={size}
      margin={margin}
      type={type}
      onClick={onClick}
      width={width}
      gap={gap}
    >
      {children}
    </StyledButtonOutline>
  ) : (
    <StyledButton
      bgColor={bgColor}
      labelColor={color}
      size={size}
      margin={margin}
      type={type}
      onClick={onClick}
      width={width}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ labelColor }) => labelColor};
  border-radius: 0.65rem;
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  font-size: ${({ size }) => size};
  font-weight: 500;
  margin: ${({ margin }) => margin};
  border: 1.5px solid transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap};

  text-align: center;
  white-space: nowrap;

  cursor: pointer;
`;

const StyledButtonOutline = styled(StyledButton)`
  color: ${({ labelColor }) => labelColor};
  background-color: ${({ theme }) => theme.colors.darkBlack};
  border: 1.5px solid ${({ bgColor }) => bgColor};

  :hover {
    background-color: ${({ bgColor }) => bgColor};
    border-color: transparent;
  }
`;

StyledButton.defaultProps = {
  width: 'max-content',
  padding: '.65rem 1.85rem',
  bgColor: '#5457f7',
  labelColor: '#fff',
  size: '1.1rem',
  margin: 'auto'
};

StyledButtonOutline.defaultProps = {
  width: 'max-content',
  padding: '.5rem 2rem',
  bgColor: '#5457f7',
  labelColor: '#fff',
  size: '1.1rem',
  margin: 'auto'
};

export default Button;
