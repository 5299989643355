import React, { useEffect, useState } from 'react';
import DataContext from 'contexts/DataContext';
import axios from 'axios';

export const DataProvider = ({ children }) => {
  const [AllEvents, setAllEvents] = useState(null);

  useEffect(() => {
    fetch('./data/Events.json')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllEvents(data);
      });
  }, []);

  useEffect(() => {
    let config = {
      method: 'get',
      url: 'https://raven-livestream-bn.herokuapp.com/api/v1/users/5edded09f228624694c7bffa/events',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlZGRlZDA5ZjIyODYyNDY5NGM3YmZmYSIsImlhdCI6MTU5MjU0NzY4OSwiZXhwIjoxNjI0MDgzNjg5fQ.aEg-EjkxoRIm_Poz4lZOmMg4eHzCuxftvJZlx7ulqvI'
        // ...data.getHeaders()
      }
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <DataContext.Provider value={{ AllEvents }}>
      {children}
    </DataContext.Provider>
  );
};
