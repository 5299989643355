import React from 'react';
import { StyledFilterPane } from './Styles/FilterPane.style';

function FilterPane({ children }) {
  return (
    <StyledFilterPane>
      <ul>{children}</ul>
    </StyledFilterPane>
  );
}

export default FilterPane;
