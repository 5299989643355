import styled from 'styled-components';

export const StyledHeader = styled.header`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colors.lightBlack};
  color: ${({ theme }) => theme.colors.darkGray};
  gap: 2rem;

  position: relative;
  z-index: 1000;

  svg {
    stroke: ${({ theme }) => theme.colors.white};
  }

  .search {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 1rem;

    input {
      width: 60%;
      border-radius: 0.65rem;
      background-color: ${({ theme }) => theme.colors.darkBlack};
      border: none;
      padding: 1rem;
      font-family: 'Brown';
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.darkWhite};

      &::placeholder {
        font-size: 1.1rem;
      }
    }
  }

  .searchButton {
    background: ${({ theme }) => theme.colors.purple};
    padding: 0.85rem 2rem;
    border-radius: 0.65rem;
    display: grid;
    place-items: center;

    svg {
      height: 1.5rem;
    }
  }
`;

export const HeaderNav = styled.nav`
  display: none;

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    width: 100%;
  }
`;
