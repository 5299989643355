import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import styled from 'styled-components';

function BackArrow({ size, onClick }) {
  return (
    <StyledBackArrow size={size} onClick={onClick}>
      <BiArrowBack />
    </StyledBackArrow>
  );
}

const StyledBackArrow = styled.div`
  font-size: ${({ size }) => size};
  cursor: pointer;
`;

StyledBackArrow.defaultProps = {
  size: '2rem'
};

export default BackArrow;
