import React, { useState } from 'react';
import styled from 'styled-components';

import { Flex } from 'structures/utilities/Flex.style';
import { Tabs } from 'structures/layouts/Tabs';

import Comments from './BackChannels/Comments';
import QAComponent from './BackChannels/Q & A';
import RaisedHands from './BackChannels/RaisedHands';
import Videos from './BackChannels/Videos';
import Input from 'structures/atoms/Input';

import { Stack } from 'structures/utilities/Stack.style';
import { Container } from 'structures/layouts/Styles/Container.style';

function StreamingPaneLayout() {
  const [toggleState, setToggleState] = useState(1);
  const [streamPane, setStreamPane] = useState(<Comments />);
  const [active, setActive] = useState(1);

  function setup(component, index) {
    setStreamPane(component);
    setActive(index);
  }

  function toggleTab(index) {
    setToggleState(index);
  }

  return (
    <StyledStreamingPaneLayout>
      <Tabs
        style={{
          textAlign: 'center',
          fontSize: '1.25rem',
          marginTop: '2rem'
        }}
      >
        <div
          className={toggleState === 1 ? 'active' : ''}
          onClick={() => toggleTab(1)}
        >
          Backchannels
        </div>
        <div
          className={toggleState === 2 ? 'active' : ''}
          onClick={() => toggleTab(2)}
        >
          Audience
        </div>
      </Tabs>

      <Stack className={toggleState === 1 ? 'content active' : 'content'}>
        <Flex
          padding={'1rem'}
          gap={'1rem'}
          justifyContent={'space-between'}
          className="subTabs"
        >
          <div
            className={active === 1 ? 'active' : ''}
            onClick={() => setup(<Comments />, 1)}
          >
            Comments
          </div>
          <div
            className={active === 2 ? 'active' : ''}
            onClick={() => setup(<QAComponent />, 2)}
          >
            Q & A
          </div>
          <div
            className={active === 3 ? 'active' : ''}
            onClick={() => setup(<RaisedHands />, 3)}
          >
            Raised Hands
          </div>
          <div
            className={active === 4 ? 'active' : ''}
            onClick={() => setup(<Videos />, 4)}
          >
            Videos
          </div>
        </Flex>
        {streamPane}
      </Stack>

      <Stack className={toggleState === 2 ? 'content active' : 'content'}>
        <Container>
          <h3 className="section__title">Show all Audience</h3>{' '}
        </Container>
      </Stack>
      <SendMessage />
    </StyledStreamingPaneLayout>
  );
}

const SendMessage = () => {
  return (
    <StyledSendMessage>
      <Input label={''} />
    </StyledSendMessage>
  );
};

const StyledSendMessage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  background-color: #000;
`;
const StyledStreamingPaneLayout = styled.div`
  .subTabs {
    > * {
      width: 100%;
      padding: 0.5rem;
      box-shadow: 0 0 5px #0000001a;
      text-align: center;

      display: grid;
      place-items: center;
      border-radius: 0.65rem;

      line-height: 1.1;

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
      }

      &.active {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  .content {
    display: none;

    &.active {
      display: initial;
    }
  }
`;

export default StreamingPaneLayout;
