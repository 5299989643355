import { StyledTicketCard } from 'structures/components/Cards/Styles/TicketCard.style';
import styled from 'styled-components';

export const StyledTicketPaneCard = styled(StyledTicketCard)`
  border: 0.5px solid ${({ theme }) => theme.colors.purple};
  border-bottom: 0.65rem solid ${({ theme }) => theme.colors.purple};
  gap: 0;
  padding: 20px;

  ::before,
  ::after {
    position: absolute;
    content: '';
    z-index: 1;
    height: 0.8rem;
  }

  ::before {
    top: -0.5rem;
    left: -1rem;
    width: calc(100% + 2rem);
    background-color: ${({ theme }) => theme.colors.purple};
    border-radius: 0.5rem;
  }

  ::after {
    width: 100%;
    top: 0;
    background-color: #000;
  }
  p {
    line-height: 1;
  }
`;
