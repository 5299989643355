import { Container } from 'structures/layouts/Styles/Container.style';
import React from 'react';

function SearchPage() {
  return (
    <Container>
      <p>...loading</p>
    </Container>
  );
}

export default SearchPage;
