import React, { useContext, useEffect, useState } from 'react';

import { Main } from 'structures/layouts/Styles/Container.style';
import { Flex } from 'structures/utilities/Flex.style';

import Input from 'structures/atoms/Input';
import Form from 'structures/atoms/Form';
import { FormWrapper, Wrapper } from 'structures/layouts/Styles/Wrapper.style';

import Button from 'structures/atoms/Button';
import SignupForm from 'structures/components/Forms/Signup';
import AuthContext from 'providers/AuthProvider';
import LayoutContext from 'contexts/LayoutContext';
import Loading from 'structures/atoms/Loading';

export default function Auth() {
  const { auth, setEmail, setPassword, handleLogin, message } =
    useContext(AuthContext);
  const { setComponentIsLoading, ComponentIsLoading } =
    useContext(LayoutContext);

  /* ========== useState ========== */
  const [toggleState, setToggleState] = useState(1);
  const isLoggedIn = localStorage.getItem('AuthToken');

  function toggleTab(index) {
    setToggleState(index);
  }

  function submitting() {
    handleLogin();
  }
  /* ========== New Component ========== */
  return (
    <Main style={{ display: 'grid', placeItems: 'center' }}>
      {message && <p>{message}</p>}

      <Wrapper
        direction="column"
        height="max-content"
        width="520px"
        jContent="center"
        aItems="center"
        gap="0"
      >
        <Flex
          justifyContent="space-between"
          className="toggleForms"
          style={{ padding: '0 20px' }}
        >
          <div
            className={toggleState === 1 ? 'active' : ''}
            onClick={() => toggleTab(1)}
          >
            Login
          </div>
          <div
            className={toggleState === 2 ? 'active' : ''}
            onClick={() => toggleTab(2)}
          >
            Signup
          </div>
        </Flex>
        <FormWrapper width="520px" height="max-content" aItems="flex-start">
          {/* ========== LoginForm Component ========== */}
          {toggleState === 1 ? (
            <Form name={'LoginForm'} onSubmit={handleLogin}>
              <Input
                type={'email'}
                label={'Email address'}
                required={'required'}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              <Input
                type={'password'}
                label={'Password'}
                onChange={(e) => setPassword(e.target.value.toLowerCase())}
              />
              <Button
                margin={'1rem auto 0'}
                type={'submit'}
                onClick={() => submitting()}
              >
                Login
              </Button>
            </Form>
          ) : (
            <SignupForm />
          )}

          {/* ========== SignupForm Component ========== */}
        </FormWrapper>
      </Wrapper>
    </Main>
  );
}
