import React from 'react';

/* ========== Style ========== */
import { StyledSettingsLayout } from './Styles/Settings.style';

export default function Settings() {
  /* ========== New Component ========== */
  return (
    <StyledSettingsLayout>
      <div className="grid-layout hide-mobile">
        <div className="">
          <div className="flex">
            <div className="">Settings</div>
            <div className="">push Notification</div>
          </div>
          <div className="grid-set">
            <div className="">
              <p className="title">Get Verified</p>
              <small></small>
            </div>
            <div className="">
              <p className="title">Set Default Video Quality</p>
            </div>
            <div className="">
              <p className="title">Policy and Safety Guidelines</p>
            </div>
            <div className="">
              <p className="title"> Feature your Event</p>
            </div>
            <div className="">
              <p className="title">Place Ads</p>
            </div>
          </div>
          <div className="">About Us</div>
        </div>
        <div className="">dfdf</div>
      </div>
    </StyledSettingsLayout>
  );
}
