import { Card } from 'structures/utilities/Card.style';
import styled from 'styled-components';

export const StyledTicketCard = styled(Card)`
  gap: 0.5rem;
  background-color: #000;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-family: 'Brown';

  padding: 1rem;

  border-bottom: 0.5rem solid ${({ theme }) => theme.colors.purple};

  &:hover {
    cursor: pointer;
  }

  p {
    line-height: 1.6rem;
  }
`;
