import React from 'react';
import Picture from './Picture';
import LogoImg from 'assets/img/ravenrevampedlogo.png';
import { StyledLogo } from './Styles/Logo.style';

function Logo({ height, width }) {
  return (
    <StyledLogo>
      <Picture
        style={{ flexShrink: '0' }}
        height={height}
        width={width}
        oFit="contain"
        pictureSrc={LogoImg}
      />
      <span>Raven Livestream</span>
    </StyledLogo>
  );
}

export default Logo;
