import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* ========== Icons ========== */
import { FaMicrophone } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import {
  BsCameraVideo,
  BsThreeDots,
  BsChatRightTextFill
} from 'react-icons/bs';
import { RiGalleryUploadLine } from 'react-icons/ri';
import { ImGift } from 'react-icons/im';
import { MdCallEnd } from 'react-icons/md';

/* ========== Contexts ========== */
import LayoutContext from 'contexts/LayoutContext';

/* ========== Layouts ========== */
import { StyledStreaming } from './Styles/Streaming.style';
import { Container } from 'structures/layouts/Styles/Container.style';
import StreamingPaneLayout from 'structures/components/SidePane/Streaming/Index';

export default function Streaming() {
  /* ========== useContext ========== */
  const { setPreviewComponent, setSidePane, sidePane } =
    useContext(LayoutContext);

  /* ========== Function ========== */
  function setPreview(elem) {
    setSidePane(!sidePane);
    setPreviewComponent(elem);
  }

  function gotStream(stream) {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    var audioContext = new AudioContext();

    // Create an AudioNode from the stream
    var mediaStreamSource = audioContext.createMediaStreamSource(stream);

    // Connect it to destination to hear yourself
    // or any other node for processing!
    mediaStreamSource.connect(audioContext.destination);
    var video = document.querySelector('video');
    var videoTracks = stream.getVideoTracks();
    window.stream = stream; // make variable available to browser console
    video.srcObject = stream;
  }
  function onfail(error) {
    console.log(
      "permission not granted or system don't have media devices." + error.name
    );
  }

  function disconnectMedia() {
    navigator.getUserMedia({ audio: false, video: false }, gotStream, onfail);
  }
  navigator.getUserMedia({ audio: true, video: true }, gotStream, onfail);

  /* ========== New Component ========== */
  return (
    <StyledStreaming>
      <Container>
        <video id="local"></video>
      </Container>

      <div className="streaming__controls">
        <div className="svg setting">
          <FiSettings />
        </div>
        <div className="flex">
          <div className="svg">
            <FaMicrophone />
          </div>
          <div className="svg">
            <BsCameraVideo />
          </div>
          <div className="svg">
            <RiGalleryUploadLine />
          </div>
          <div className="svg gift">
            <ImGift />
          </div>
          <div className="svg">
            <BsThreeDots />
          </div>
          <Link to={'/live'} onClick={disconnectMedia}>
            <div className="svg end">
              <MdCallEnd />
            </div>
          </Link>
        </div>
        <div
          className="svg chat"
          onClick={() => setPreview(<StreamingPaneLayout />)}
        >
          <BsChatRightTextFill />
        </div>
      </div>
    </StyledStreaming>
  );
}
