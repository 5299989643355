import { Card } from 'structures/utilities/Card.style';
import styled from 'styled-components';

export const StyledEventCard = styled(Card)`
  line-height: 1;
  font-family: 'Brown';
  font-weight: lighter;

  &:hover {
    cursor: pointer;
    transform: translateY(0.35rem);

    .event__picture {
      img {
        transform: scale(1.1);
      }
    }
  }
`;
