import React from 'react';

import HomePage from 'structures/pages/Index';
import Live from 'structures/pages/Live';
import Play from 'structures/pages/Play';
import ProfilePage from 'structures/pages/Profile';
import Streaming from 'structures/pages/Streaming';
import Settings from 'structures/pages/Settings';
import TicketsPage from 'structures/pages/Tickets';
import SearchPage from 'structures/pages/Search';
import Auth from 'structures/pages/Auth';

export const routePaths = [
  {
    name: 'Home',
    path: '/',
    component: <HomePage />
  },

  {
    name: 'Play',
    path: '/play',
    component: <Play />
  },
  {
    name: 'Live',
    path: '/live',
    component: <Live />
  },
  {
    name: 'Profile',
    path: '/profile',
    component: <ProfilePage />
  },
  {
    name: 'Streaming',
    path: '/streaming',
    component: <Streaming />
  },
  {
    name: 'Tickets',
    path: '/tickets',
    component: <TicketsPage />
  },
  {
    name: 'Settings',
    path: '/settings',
    component: <Settings />
  },
  {
    name: 'Search',
    path: '/search',
    component: <SearchPage />
  }
];
