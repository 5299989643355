import React from 'react';

/* ========== Layouts ========== */
import { StyledPrice } from 'structures/utilities/Card.style';
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import { StyledTicketPaneCard } from '../styles/Ticket.style';
import { Container } from 'structures/layouts/Styles/Container.style';

/* ========== Components ========== */
import LineDivider from 'structures/atoms/LineDivider';
import CreatorImg from 'assets/img/Avatar.png';
import { Avatar } from 'structures/atoms/Avatar';
import Button from 'structures/atoms/Button';

export default function TicketsPreview() {
  /* ========== New Component ========== */
  return (
    <>
      <Container padding="4rem 2rem 2rem" style={{ fontFamily: 'Brown' }}>
        <p
          style={{ textAlign: 'center', color: 'gray', paddingBottom: '1rem' }}
        >
          click ticket to view event details
        </p>
        <StyledTicketPaneCard style={{ paddingBottom: '0' }}>
          <p
            style={{
              fontSize: '1.35rem',
              lineHeight: 1.2,
              paddingBottom: '20px'
            }}
          >
            Make up Masterclass w/the best in the industry
          </p>
          <Flex
            justifyContent={'space-between'}
            padding={'1rem 0'}
            style={{ borderTop: '1px solid gray', color: 'gray' }}
          >
            <p>1 Feb, 2020</p>
            <p>08:45pm</p>
          </Flex>
          <Stack
            gap={'.25rem'}
            padding={'1rem 0'}
            style={{ borderTop: '1px solid gray' }}
          >
            <p>2 Tickets Avaliable</p>
            <StyledPrice style={{ fontSize: '1.75rem' }}>$14.99</StyledPrice>
          </Stack>

          <Flex
            justifyContent={'center'}
            gap={'1rem'}
            alignItem={'center'}
            padding={'1rem 0'}
            width={'max-content'}
            style={{
              borderTop: '1px solid gray'
            }}
          >
            <Avatar avatarImg={CreatorImg} />
            <Stack gap={'.25rem'} style={{ textAlign: 'left' }}>
              <p>Jerome Agatha</p>
              <p style={{ color: 'gray' }}>Creator</p>
            </Stack>
          </Flex>
        </StyledTicketPaneCard>
      </Container>

      <Stack gap={'1rem'}>
        <Button width={'60%'}>Share Ticket </Button>
        <Button width={'60%'} outline={true}>
          Cancel Ticket
        </Button>
      </Stack>
    </>
  );
}
