import styled from 'styled-components';

export const StyledEventPreview = styled.div`
  height: 100%;
  font-family: 'Brown';

  button.absolute {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    background-color: #000000c4;
    color: ${({ theme }) => theme.colors.purple};
  }

  .event {
    &__link {
      margin-left: auto;
      background-color: #000000c4;
      color: ${({ theme }) => theme.colors.purple};
    }

    &__guest {
      background-color: #000;
      border-radius: 0.65rem;
      padding: 0.35rem 0.65rem;
    }

    &__body {
      margin-top: -1.5rem;
      background-color: ${({ theme }) => theme.colors.black};
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
    }

    &__description {
      p {
        line-height: 1.6;
      }
    }

    &__action {
      width: 100%;
      padding: 1rem;
      display: grid;
      place-items: center;

      button {
        width: 80%;
        text-align: center;
        justify-content: center;
      }
    }
  }
`;

export const ProfileTag = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.35rem 0.65rem;
  border-radius: 0.65rem;
  background-color: #000;
`;

ProfileTag.defaultProps = {
  backgroundColor: '#000'
};
