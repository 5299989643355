import styled from 'styled-components';

export const StyledFilterPane = styled.div`
  background-color: #141518;
  padding: 0.15rem 2rem;
  width: 100%;
  height: max-content;

  display: flex;

  ul {
    display: flex;
    list-style: none;
    padding: 0 2rem;
    gap: 2rem;

    li {
      border-bottom: 3px solid transparent;
      cursor: pointer;
      font-family: 'Brown';
      font-size: 0.95rem;

      &.active,
      &:hover {
        border-color: ${({ theme }) => theme.colors.purple};
      }
    }
  }
`;
