import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  max-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  padding: ${({ padding }) => padding};

  display: flex;
  flex-direction: ${({ direction }) => direction};

  justify-content: ${({ jContent }) => jContent};
  align-items: ${({ aItems }) => aItems};
  gap: ${({ gap }) => gap};

  margin: 0 auto;

  .toggleForms {
    font-family: 'Brown';

    > * {
      text-align: center;
      padding: 20px;
      background-color: ${({ theme }) => theme.colors.purple};
      border: 1px solid ${({ theme }) => theme.colors.purple};
      width: 100%;

      &.active {
        background-color: transparent;
      }

      &:first-child {
        border-top-left-radius: 0.65rem;
        border-bottom-left-radius: 0.65rem;
      }

      &:last-child {
        border-top-right-radius: 0.65rem;
        border-bottom-right-radius: 0.65rem;
      }
    }
  }
`;

Wrapper.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  padding: '20px',
  maxWidth: '100%'
  //   maxHeight: '100%'
};

export const FormWrapper = styled(Wrapper)`
  overflow: hidden;

  > * {
    flex-shrink: 0;
  }
`;
