import React from 'react';

/* =========== Components =========== */
import AvatarImg from 'assets/img/Avatar.png';

/* =========== Layouts =========== */
import { StyledEventCard } from './Styles/EventCard.style';
import { StyledPrice } from 'structures/utilities/Card.style';
import { Flex } from 'structures/utilities/Flex.style';
import { Avatar } from 'structures/atoms/Avatar';
import IsVerified from 'structures/atoms/IsVerified';
import { Stack } from 'structures/utilities/Stack.style';
import CalendarPreview from 'structures/atoms/CalendarPreview';
import Picture from 'structures/atoms/Picture';

const EventCard = ({ name, price, creatorName, PictureSrc, onClick }) => {
  /* ========== New Component ========== */
  return (
    <StyledEventCard gap=".65rem" pad=".65rem" onClick={onClick}>
      <Flex gap=".5rem" alignItem={'center'}>
        <Avatar avatarImg={AvatarImg} />
        <Stack gap=".3rem">
          <Flex gap=".5rem" alignItem={'center'}>
            {creatorName}
            <IsVerified />
          </Flex>
          <div
            className="event__date"
            style={{ color: 'gray', fontSize: '.95rem' }}
          >
            10 April 2022 at 10:00
          </div>
        </Stack>
        <StyledPrice>{price}</StyledPrice>
      </Flex>

      <Stack>
        <CalendarPreview month={'APR'} day={'12'} />
        <Picture
          border={'4px solid #141518'}
          radius={'1rem'}
          height="180px"
          pictureSrc={PictureSrc}
          oFit={'cover'}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '.35rem'
          }}
        >
          {name}
        </p>
      </Stack>
    </StyledEventCard>
  );
};

export default EventCard;
