import React, { useContext } from 'react';

/* ========== Components ========== */
import ManageEventCard from 'structures/components/Cards/ManageEvent';
import { Container } from 'structures/layouts/Styles/Container.style';

/* ========== Import Context ========== */
import DataContext from 'contexts/DataContext';

export default function ManageEventsPreview() {
  const { AllEvents } = useContext(DataContext);

  /* ========== New Component ========== */
  return (
    <Container padding="1rem 2rem">
      <h3 className="SidePane__title">Manage Events</h3>
      {AllEvents.filter((Event) => Event.isFeatured).map(
        ({ name, isHosted }) => (
          <ManageEventCard
            key={name}
            title={name}
            date="12 / 6 / 2022"
            time="10:00"
            ishosted={isHosted}
          />
        )
      )}
    </Container>
  );
}
