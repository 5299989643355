const { createGlobalStyle } = require('styled-components');

const GlobalStyles = createGlobalStyle`

html {
  height: 100%;
  width: 100%;
  font-size: 70%;

  @media (min-width: ${({ theme }) => theme.screens.minSize.laptop}){
 
    font-size: 80%;
  }

  @media (min-width:${({ theme }) => theme.screens.minSize.largeScreen}){
    font-size: 90%;
  }
}

* {
  font-family: inherit;
  transition: 0.3s ease-in-out;
  &,
  &::before,
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
  }
}

#root,
#app {
  height: 100%;
  position: relative;
  z-index: 1000;
}

body {
  height: 100%;
  width: 100%;
  background: #000;
  transition: all 0.05s linear;
  line-height: 2.1rem;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: none;
  border: none;
}

main{
  overflow-y: scroll;
  height: calc(100vh - 5.5rem);
  padding-bottom: 7rem;
  scrollbar-width: none;
  width: 100%;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  transition: 0.8s;

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    padding-bottom: 1.5rem;
  }
}

.hide-mobile {
  display: none;

  @media (min-width: $ipad) {
    display: block;
  }
}

.show-mobile {
  display: block;

  @media (min-width: $ipad) {
    display: none;
  }
}

.offScroll {
  overflow: hidden;
}
`;

export default GlobalStyles;
