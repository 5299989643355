import React from 'react';

import { Container } from 'structures/layouts/Styles/Container.style';

export default function NotificationPreview() {
  /* ========== New Component ========== */
  return (
    <Container padding="1rem 2rem">
      <h3 className="SidePane__title">Notifications</h3>
    </Container>
  );
}
