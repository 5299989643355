import styled from 'styled-components';

export const StyledAside = styled.aside`
  background-color: ${({ theme }) => theme.colors.lightBlack};
  color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 10003;

  position: absolute;
  bottom: 0;

  transition: 0.5s;

  button {
    font-family: 'Barlow';
  }

  .aside__nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3.5rem;

    .primary-action {
      display: grid;
      place-items: center;
      background: ${({ theme }) => theme.colors.linearPurple};
      padding: 1rem;
      height: 5rem;
      width: 4.7rem;
      border-radius: 50%;
      cursor: pointer;
    }

    &--item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.2rem;

      p {
        line-height: 1;
        font-weight: 300;
        font-size: 1.1rem;
        letter-spacing: 1px;
      }

      &:hover,
      &.active {
        color: ${({ theme }) => theme.colors.purple};
        cursor: pointer;

        svg {
          path {
            fill: none;
            stroke: ${({ theme }) => theme.colors.purple};
          }
        }
      }
    }

    svg {
      color: inherit;
      width: 20px;
      height: 20px;

      &.play {
        width: 2rem;
        height: 2rem;
      }

      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .more {
    position: absolute;
    cursor: pointer;
    bottom: 50%;
    transform: translateY(50%);
    right: 2rem;

    &:hover,
    &.active {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }

  .more__modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6rem;
    width: 100%;
    border-radius: 0.65rem;
    padding: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    display: grid;
    gap: 1rem;

    button {
      width: 100%;
      font-weight: 300;
      font-size: 1.2rem;
      justify-content: center;
    }

    &.closed {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    position: relative;
    top: 0;
    left: 0;
    height: calc(100vh - 5.5rem);
    z-index: 1000;
    width: 6rem;
    flex-shrink: 0;

    .aside__nav {
      flex-direction: column;
      gap: 2.5rem;
    }

    .more {
      bottom: 3rem;
      transform: translateY(0);

      &__modal {
        left: calc(100% + 1rem);
        right: unset;
        width: 350px;
      }
    }
  }
`;
