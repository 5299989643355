import React from 'react';
import styled from 'styled-components';

import { Stack } from 'structures/utilities/Stack.style';
import Button from 'structures/atoms/Button';

const ButtonOutline = {
  width: '200px',
  justifyContent: 'center',
  fontWeight: 300,
  backgroundColor: 'transparent',
  border: '1px solid #5458f7'
};

export default function ManageEventCard({
  date,
  time,
  title,
  classes,
  ishosted
}) {
  classes = ['ManageEventCard'];

  /* ====== New Component ====== */
  return (
    <div className={classes}>
      <div className="event__time">{time}</div>
      <Stack>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.2'
          }}
        >
          {title}
        </p>
        <span style={{ color: 'gray' }}>{date}</span>
      </Stack>

      <Button width={'200px'} outline={true}>
        {ishosted ? <span>Manage</span> : <span>View</span>}
      </Button>
    </div>
  );
}

const StyledManageEventCard = styled.div``;
