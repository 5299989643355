import React from 'react';
import { StyledAvatar } from './Styles/Avatar.style';

function Avatar({ avatarImg, height, width }) {
  return (
    <StyledAvatar height={height} width={width}>
      <img src={avatarImg} />
    </StyledAvatar>
  );
}

export { Avatar };
