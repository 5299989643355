import { Container } from 'structures/layouts/Styles/Container.style';
import React from 'react';

function Comments() {
  return (
    <Container padding="1rem 2rem">
      <h3 className="SidePane__title">Comments</h3>
    </Container>
  );
}

export default Comments;
