import styled from 'styled-components';

export const StyledSidepane = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  height: calc(100% - 5.9rem);

  background-color: ${({ theme }) => theme.colors.black};

  z-index: 101;
  margin-left: auto;

  justify-self: flex-end;
  display: flex;
  flex-direction: column;

  > * {
    opacity: 1;
  }

  .SidePane__title {
    font-family: 'Brown';
    font-weight: 300;
    font-size: 2rem;
    padding: 0.5rem 0;
    padding-bottom: 2rem;
  }

  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.notActive {
    min-width: 0;
    width: 0;

    > * {
      opacity: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    position: relative;
    border-left: 2px solid ${({ theme }) => theme.colors.lightBlack};
    height: 100% !important;
    right: 0;
    transform: scaleX(1);
    transform-origin: left;
    min-width: 280px;
    max-width: 380px;
  }

  @media (min-width: ${({ theme }) => theme.screens.minSize.largeScreen}) {
    max-width: 420px;
    height: 100%;
  }
`;

export const StyledSidePaneCloser = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;

  position: absolute;
  top: 1.25rem;
  right: 1.25rem;

  height: 2.6rem;
  width: 2.6rem;

  z-index: 20;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  .svgContainer {
    height: 100%;
    width: 100%;
    padding: 0.9rem;
    display: grid;
    place-items: center;
  }

  &:hover {
    box-shadow: 0 0 5px rgba($color: white-200, $alpha: 0.3);

    .svgContainer {
      transform: scale(1.2);
    }
  }
`;
