import React from 'react';
import styled from 'styled-components';

function CalendarPreview({ month, day }) {
  return (
    <StyledCalendarPreview>
      <span>{month}</span>
      <span>{day}</span>
    </StyledCalendarPreview>
  );
}

export const StyledCalendarPreview = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;

  height: max-content;
  width: max-content;

  padding: 0.25rem 0.45rem;
  z-index: 20;

  display: grid;
  place-items: center;
  gap: 0.05rem;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  line-height: 1;

  color: ${({ theme }) => theme.colors.lightBlack};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.35rem;

  font-size: 0.85rem;

  span:first-child {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export default CalendarPreview;
