import React from 'react';
import styled from 'styled-components';

function Picture({
  pictureSrc,
  height,
  width,
  oFit,
  border,
  shadow,
  radius,
  children
}) {
  return (
    <StyledPicture
      height={height}
      border={border}
      radius={radius}
      oFit={oFit}
      width={width}
      shadow={shadow}
    >
      <img src={pictureSrc} />
      {children}
    </StyledPicture>
  );
}

const StyledPicture = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height};
  width: 100%;
  max-width: ${({ width }) => width};
  pointer-events: visible;
  border: ${({ border }) => border};
  border-radius: ${({ radius }) => radius};
  box-shadow: ${({ shadow }) => shadow};
  flex-shrink: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${({ oFit }) => oFit};
  }
`;

StyledPicture.defaultProps = {};
export default Picture;
