import styled from 'styled-components';

export const StyledSettingsLayout = styled.main`
  .grid-layout {
    display: grid;
    grid-template-columns: 450px 1fr;
    height: 100%;

    > div {
      padding: 2rem;
      height: 100%;

      &:last-child {
        border-left: 2px solid ${({ theme }) => theme.colors.black};
      }
    }

    .flex {
      padding: 2rem;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 1rem;
    }

    .grid-set {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      > div {
        padding: 2rem;
        background-color: ${({ theme }) => theme.colors.black};
        border-radius: 0.65rem;
        cursor: pointer;
        border: 1px solid transparent;

        &:hover {
          background-color: transparent;
          border-color: ${({ theme }) => theme.colors.purple};

          .title {
            color: ${({ theme }) => theme.colors.purple};
          }
        }

        .title {
          font-size: 1.5rem;
          letter-spacing: 0.05rem;
        }
      }
    }
  }
`;
