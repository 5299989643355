import React from 'react';
import styled from 'styled-components';

function IsVerified() {
  return <StyledIsVerfied></StyledIsVerfied>;
}

const StyledIsVerfied = styled.span`
  display: flex;
  height: 1.15rem;
  width: 1.15rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.purple};
`;

export default IsVerified;
