import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* ========== Context ========== */
import { AppContext } from 'App';

/* ========== Layout ========== */
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import Button from 'structures/atoms/Button';
import LayoutContext from 'contexts/LayoutContext';

const ButtonOutline = {
  width: '200px',
  height: 'max-content',
  justifyContent: 'center',
  fontWeight: 300,
  backgroundColor: 'transparent',
  border: '1px solid #5458f7'
};

function MyEventCard({ time, title, ishosted }) {
  /* ========== useContext ========== */
  const { setPreviewWithOverlay } = useContext(LayoutContext);

  /* ========== New Component ========== */
  return (
    <Flex
      gap="2rem"
      justifyContent="space-between"
      style={{ marginBottom: '1rem' }}
    >
      <div className="event__time">{time}</div>
      <Stack>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.2'
          }}
        >
          {title}
        </p>
        <Flex justifyContent="space-between" style={{ color: 'gray' }}>
          {ishosted ? <>Host</> : <>User</>} <span>{time}</span>
        </Flex>
      </Stack>

      <Button
        outline={true}
        width={'200px'}
        onClick={() => setPreviewWithOverlay('', false)}
      >
        <Link to={'/streaming'} style={{ width: '100%' }}>
          {ishosted ? <span>Start</span> : <span>View</span>}
        </Link>
      </Button>
    </Flex>
  );
}

export default MyEventCard;
