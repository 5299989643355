import React from 'react';
import styled from 'styled-components';

function LineDivider({ margin }) {
  return <StyledLineDivider margin={margin} />;
}

const StyledLineDivider = styled.div`
  margin: ${({ margin }) => margin};
  height: 0.15rem;
  width: 100%;
  background-color: #ffffff5e;
`;

StyledLineDivider.defaultProps = {
  margin: '1rem 0'
};

export default LineDivider;
