import React, { useContext, useEffect, useState } from 'react';

import IsVerified from 'structures/atoms/IsVerified';
import Picture from 'structures/atoms/Picture';
import EventPreview from 'structures/components/SidePane/Events/EventId';
import { Container } from 'structures/layouts/Styles/Container.style';
import { Cards } from 'structures/utilities/Cards.style';
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import {
  FiShare2,
  FiTwitter,
  FiYoutube,
  FiLink,
  FiMessageSquare
} from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';

import { StyledProfilePage } from './Styles/Profile.style';
import Button from 'structures/atoms/Button';

import LayoutContext from 'contexts/LayoutContext';
import DataContext from 'contexts/DataContext';

export default function ProfilePage() {
  const { setPreviewWithOverlay } = useContext(LayoutContext);
  const { AllEvents } = useContext(DataContext);

  const User = JSON.parse(localStorage.getItem('User'));
  const {
    username,
    name,
    ID,
    nationality,
    profession,
    picture_id,
    views,
    followers,
    following
  } = User;

  /* ========== New Component ========== */
  return (
    <StyledProfilePage>
      <Picture height="300px" pictureSrc={'assets/img/Cover.png'}></Picture>
      <Container padding="2rem">
        <Flex justifyContent="flex-start" alignItem="center" className="pushUp">
          <Picture
            height={'12rem'}
            width={'12rem'}
            pictureSrc={picture_id}
            radius={'50%'}
            oFit={'cover'}
            shadow={'0 0 0 .65rem #090a0d'}
          />
          <Flex justifyContent="space-between" style={{ marginTop: '5rem' }}>
            <Flex gap="1rem">
              <Button margin={'0 0 0 2rem'} outline={true} gap={'.5rem'}>
                <FiMessageSquare />
                Message
              </Button>
              <Button margin={0}>Follow</Button>
            </Flex>
            <Button margin={0} outline={true} gap={'.5rem'}>
              <FiShare2 />
              Share via
            </Button>
          </Flex>
        </Flex>

        <Flex>
          <Stack style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Flex
              alignItem="center"
              gap="1rem"
              style={{ fontSize: '1.5rem', lineHeight: '1.3' }}
            >
              {username} <IsVerified />
            </Flex>
            <div
              className="profile__name"
              style={{ color: 'gray', lineHeight: '1.3' }}
            >
              {name}
            </div>
            <div
              className="profile__nationality"
              style={{ color: 'gray', lineHeight: '1.3' }}
            >
              {nationality}
            </div>
          </Stack>
          <Flex
            gap="2rem"
            alignItem="center"
            justifyContent="flex-end"
            style={{ fontSize: '1.35rem', color: '#5458f7' }}
          >
            <div>
              <FaInstagram />
            </div>
            <div>
              <FiTwitter />
            </div>
            <div>
              <FiYoutube />
            </div>
            <div>
              <FiLink />
            </div>
          </Flex>
        </Flex>

        <Flex>
          <p style={{ fontFamily: 'Brown', lineHeight: '1.3' }}>
            Hi, my name is {name}, I&apos;m {profession} from {nationality} and
            I love to make skits and meet new people.
          </p>

          <Flex
            gap="2rem"
            alignItem="center"
            justifyContent="flex-end"
            style={{ lineHeight: 1.6 }}
          >
            <Flex
              direction="column"
              justifyContent="center"
              width="max-content"
              alignItem="center"
            >
              10
              <span style={{ color: 'gray' }}>Events</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
            >
              {following}
              <span style={{ color: 'gray' }}>Following</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
            >
              {followers}M<span style={{ color: 'gray' }}>Followers</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
            >
              {views}
              <span style={{ color: 'gray' }}>Views</span>
            </Flex>
          </Flex>
        </Flex>
        <Cards pad="2rem 0" minSize="240px" cGap="20px" rGap="20px">
          {AllEvents &&
            AllEvents.map(({ name, img }) => (
              <div
                key={name}
                style={{ cursor: 'pointer' }}
                onClick={() => setPreviewWithOverlay(<EventPreview />, true)}
              >
                <Picture
                  height="180px"
                  pictureSrc={`assets/img/events/${img}`}
                  oFit={'cover'}
                  radius={'1rem'}
                ></Picture>
              </div>
            ))}
        </Cards>
      </Container>
    </StyledProfilePage>
  );
}
