import React, { useContext, useState, useEffect } from 'react';

/* ========== Components ========== */
import EventCard from 'structures/components/Cards/Event';
import { Container } from 'structures/layouts/Styles/Container.style';
import { Cards } from 'structures/utilities/Cards.style';
import FilterPane from 'structures/atoms/FilterPane';
import { homeFilters } from 'data/Filters';
import EventPreview from 'structures/components/SidePane/Events/EventId';

/* ========== Contexts ========== */
import LayoutContext from 'contexts/LayoutContext';
import DataContext from 'contexts/DataContext';

import { Stack } from 'structures/utilities/Stack.style';
import ForYouRender from './HomeTabs/ForYou';

export default function HomePage() {
  /* ========== useContext  ========== */
  const { setPreviewWithOverlay } = useContext(LayoutContext);
  const { AllEvents } = useContext(DataContext);

  /* ========== useState  ========== */
  const [toggleState, setToggleState] = useState(1);
  const [HomeComponent, setHomeComponent] = useState(<ForYouRender />);

  function toggleTab(index) {
    setToggleState(index);
  }

  /* ========== New Component ========== */
  return (
    <>
      <FilterPane>
        {homeFilters.map(({ name, id }) => (
          <li
            className={toggleState === id ? 'active' : ''}
            key={id}
            onClick={() => {
              toggleTab(id);
            }}
          >
            {name}
          </li>
        ))}
      </FilterPane>
      {toggleState === 1 ? (
        <Stack>
          <Container>
            <Cards no="2" minSize="240px" rGap="2rem">
              {AllEvents &&
                AllEvents.map(({ name, price, creatorName, img }) => (
                  <EventCard
                    key={name}
                    name={name}
                    price={price}
                    creatorName={creatorName}
                    PictureSrc={`assets/img/events/${img}`}
                    onClick={() =>
                      setPreviewWithOverlay(<EventPreview />, true)
                    }
                  />
                ))}
            </Cards>
          </Container>

          <Container bgColor="#5457f71c">
            <h3 className="text__purple font__brown">Featured Event</h3>
            <Cards no="2" minSize="240px" rGap="2rem">
              {AllEvents &&
                AllEvents.filter((Event) => Event.isFeatured).map(
                  ({ name, price, creatorName, img }) => (
                    <EventCard
                      key={name}
                      name={name}
                      price={price}
                      creatorName={creatorName}
                      PictureSrc={`assets/img/events/${img}`}
                      onClick={() =>
                        setPreviewWithOverlay(<EventPreview />, true)
                      }
                    />
                  )
                )}
            </Cards>
          </Container>
        </Stack>
      ) : (
        ''
      )}
      {toggleState === 2 ? (
        <Stack>
          <Container>
            <Cards no="2" minSize="240px" rGap="2rem">
              {AllEvents &&
                AllEvents.filter((Event) => Event.isTrending).map(
                  ({ name, price, creatorName, img }) => (
                    <EventCard
                      key={name}
                      name={name}
                      price={price}
                      creatorName={creatorName}
                      PictureSrc={`assets/img/events/${img}`}
                      onClick={() =>
                        setPreviewWithOverlay(<EventPreview />, true)
                      }
                    />
                  )
                )}
            </Cards>
          </Container>
        </Stack>
      ) : (
        ''
      )}
      {toggleState === 3 ? (
        <Stack>
          <Container>
            <Cards no="2" minSize="240px" rGap="2rem">
              {AllEvents.map(({ name, price, creatorName, img }) => (
                <EventCard
                  key={name}
                  name={name}
                  price={price}
                  creatorName={creatorName}
                  PictureSrc={`assets/img/events/${img}`}
                  onClick={() => setPreviewWithOverlay(<EventPreview />, true)}
                />
              ))}
            </Cards>
          </Container>
        </Stack>
      ) : (
        ''
      )}
      {toggleState === 4 ? <Container>No Live Event Yet!</Container> : ''}
    </>
  );
}
