import styled from 'styled-components';

export const Tabs = styled.div`
  display: flex;

  > * {
    width: 100%;
    padding: ${({ padding }) => padding};
    border-bottom: 2px solid gray;
    line-height: 1.1;

    display: grid;
    place-items: center;
    text-align: center;

    &.active,
    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.colors.purple};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

Tabs.defaultProps = {
  padding: '10px 20px'
};
