import styled from 'styled-components';

export const MYATMCard = styled.div`
  position: relative;

  min-height: 180px;
  height: 20vh;
  max-width: 350px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  border-radius: 1rem;
  background: radial-gradient(100% 100% at 0% 0%, #ffffff66 0%, #ffffff00 100%);

  ::after,
  ::before {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    content: '';

    border-radius: 1rem;
  }

  ::after {
    z-index: -2;
    height: calc(100% + 3px);
    width: calc(100% + 3px);
    background: radial-gradient(
      2.03% 50% at 50% 50%,
      #f53e02 0%,
      #f53e0294 50%
    );
  }

  ::before {
    z-index: -1;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.darkBlack};
  }

  .wallet {
    &__currency {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #84848665;
      display: grid;
      place-items: center;
      border-radius: 50%;
    }

    &__balance {
      color: ${({ theme }) => theme.colors.white};

      span {
        margin-top: 0.1rem;
        display: block;
        font-size: 1.5rem;
      }
    }
  }
`;

export const StyledWallet = styled.div`
  padding: 1rem 2rem;
  overflow-y: hidden;
  color: ${({ theme }) => theme.colors.white}; //.8 transparent

  .SidePane__title {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  .container {
    padding: 1rem;

    &:first-of-type {
      padding: 1rem 1.5rem;
    }
  }

  .this-title {
    font-size: 1.5rem;
    letter-spacing: 0.1px;
    font-weight: 300;
    font-family: 'Barlow';
    padding-bottom: 1rem;
    text-align: center;
  }

  .wallet-activity {
    line-height: 1.2;
    font-size: 1.05rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    letter-spacing: 0.1px;

    .illustration {
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors.black};
      border-radius: 0.65rem;
    }

    text-align: center;
    width: 100%;
  }
`;
