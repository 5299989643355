import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* ========== Icons ========== */
import { ReactComponent as SearchIcon } from 'assets/icons/svg/search_icon.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/svg/wallet_icon.svg';
import { ReactComponent as EventIcon } from 'assets/icons/svg/Plan_Event.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/svg/notification_icon.svg';
import { ReactComponent as TicketIcon } from 'assets/icons/svg/ticket_icon.svg';

/* ========== Atoms ========== */
import Picture from 'structures/atoms/Picture';
import { IconWrapper } from 'structures/atoms/Icon';
import { Flex } from '../utilities/Flex.style';
import Logo from 'structures/atoms/Logo';

/* ========== Preview Components ========== */
import WalletPreview from 'structures/components/SidePane/Wallet/Index';
import ManageEventsPreview from 'structures/components/SidePane/Events/ManageEvents';
import NotificationPreview from 'structures/components/SidePane/Notifications/Notification';

/* ========== Styles ========== */
import { HeaderNav, StyledHeader } from './Styles/Header.style';

/* ========== Import Context ========== */
import LayoutContext from 'contexts/LayoutContext';
import AuthContext from 'providers/AuthProvider';

const Header = () => {
  const { setPreviewWithOverlay } = useContext(LayoutContext);
  const { auth } = useContext(AuthContext);

  /* ========== New Component ========== */
  return (
    <StyledHeader>
      <Link to={'/'}>
        <Logo height="3.5rem" width="3.5rem" />
      </Link>
      {auth ? (
        <HeaderNav>
          <div className="search">
            <input
              type="text"
              placeholder="Search for events, people and ticket"
            />
            <Link to={'/search'}>
              <div className="searchButton">
                <SearchIcon />
              </div>
            </Link>
          </div>

          <Flex gap="1.5rem" width="max-content">
            <IconWrapper
              onClick={() => setPreviewWithOverlay(<WalletPreview />, true)}
            >
              <WalletIcon />
            </IconWrapper>

            <IconWrapper
              onClick={() =>
                setPreviewWithOverlay(<ManageEventsPreview />, true)
              }
            >
              <EventIcon />
            </IconWrapper>

            <IconWrapper
              onClick={() =>
                setPreviewWithOverlay(<NotificationPreview />, true)
              }
            >
              <NotificationIcon />
            </IconWrapper>

            <Link to={'/tickets'}>
              <IconWrapper>
                <TicketIcon />
              </IconWrapper>
            </Link>

            <Link to={'/profile'}>
              <IconWrapper>
                <Picture
                  height={'100%'}
                  // pictureSrc={`assets/img/users/${picture_id}`}
                  oFit={'cover'}
                  radius={'50%'}
                />
              </IconWrapper>
            </Link>
          </Flex>
        </HeaderNav>
      ) : (
        <a href="https://ravenlivestream.com">Download App</a>
      )}
    </StyledHeader>
  );
};

export default Header;
