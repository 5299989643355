import React from 'react';
import { BiArrowBack } from 'react-icons/bi';

/* ========== Layouts ========== */
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import { Container } from 'structures/layouts/Styles/Container.style';
import { Tabs } from 'structures/layouts/Tabs';

function Withdraw() {
  return (
    <Container name="withdraw">
      {/* <Flex alignItem="center" gap="2rem">
        <BiArrowBack />
        <h4>Withdraw</h4>
      </Flex> */}
      <Flex
        direction="column"
        justifyContent="center"
        style={{ textAlign: 'center' }}
      >
        <h2>Withdraw to</h2>
        <Tabs style={{ borderBottom: 'none' }}>
          <div>Bank Account</div>
          <div>PayPal</div>
        </Tabs>
      </Flex>
    </Container>
  );
}

export default Withdraw;
