import { Container } from 'structures/layouts/Styles/Container.style';
import React from 'react';

function RaisedHands() {
  return (
    <Container>
      <h3 className="SidePane__title">RaisedHands</h3>
    </Container>
  );
}

export default RaisedHands;
