import AuthContext from 'providers/AuthProvider';
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoute({ redirectPath = '/auth', children }) {
  const { auth } = useContext(AuthContext);

  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;
