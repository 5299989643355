import React, { useContext } from 'react';

import TicketCard from 'structures/components/Cards/Ticket';
import { Cards } from 'structures/utilities/Cards.style';

/* ========== Atoms ========== */

import Avatar from 'assets/img/Avatar.png';

/* ========== Contexts ========== */
import LayoutContext from 'contexts/LayoutContext';
import DataContext from 'contexts/DataContext';

/* ========== Style ========== */
import { StyledTicketsPage } from './Styles/Tickets.style';
import TicketsPreview from 'structures/components/SidePane/Tickets/Ticket';

export default function TicketsPage() {
  /* ========== useContext  ========== */
  const { setPreviewWithOverlay } = useContext(LayoutContext);
  const { AllEvents } = useContext(DataContext);

  /* ===== New Component ===== */
  return (
    <StyledTicketsPage>
      <Cards minSize="160px" rGap="20px" cGap="20px" pad="20px" no="2">
        {AllEvents &&
          AllEvents.map(({ name, price, description }) => (
            <TicketCard
              key={name}
              description={description}
              price={price}
              creatorAvatar={Avatar}
              onClick={() => setPreviewWithOverlay(<TicketsPreview />, true)}
            />
          ))}
      </Cards>
    </StyledTicketsPage>
  );
}
