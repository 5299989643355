import React, { useContext, useState } from 'react';

/* ========== Icons ========== */
import { ReactComponent as DepositIcon } from 'assets/icons/svg/money.svg';
import { ReactComponent as WithdrawalIcon } from 'assets/icons/svg/ticket_icon.svg';
import { ReactComponent as TransactionHistoryIcon } from 'assets/icons/svg/currency.svg';

/* ========== Components ========== */
import { MYATMCard, StyledWallet } from '../styles/Wallet.style';
import LineDivider from 'structures/atoms/LineDivider';
import { Flex } from 'structures/utilities/Flex.style';
import { Stack } from 'structures/utilities/Stack.style';
import { Container } from 'structures/layouts/Styles/Container.style';
import { Tabs } from 'structures/layouts/Tabs';
import Deposit from './Deposit';
import TransactionHistory from './TransactionHistory';
import Withdraw from './Withdraw';
import BackArrow from 'structures/atoms/BackArrow';
import { AppContext } from 'App';
import currencyFormatter from 'utils/currencyFormatter';

function WalletPreview({ eDate }) {
  const User = JSON.parse(localStorage.getItem('User'));
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabComponent, setActiveTabComponent] = useState('');

  function toggleTab(index, component) {
    setActiveTab(index);
    setActiveTabComponent(component);
  }

  eDate = '1 / 2';

  /* ========== New Component ========== */
  return (
    <StyledWallet>
      <Flex alignItem="center" gap="1rem" className="SidePane__title">
        {activeTabComponent !== '' ? (
          <BackArrow
            onClick={() => {
              setActiveTabComponent('');
              setActiveTab(0);
            }}
          />
        ) : (
          ''
        )}
        Wallet
      </Flex>

      <Container padding="0 1rem" style={{ paddingBottom: '2rem' }}>
        <MYATMCard>
          <Flex justifyContent="space-between">
            <div className="wallet__balance">
              Available balance
              <span>{currencyFormatter.format(User.walletBalance)}.00</span>
            </div>
            <div className="wallet__currency">$</div>
          </Flex>

          <Flex justifyContent="space-between">
            <div className="wallet__owner">{User.name}</div>
            <div className="wallet__eDate">{eDate}</div>
          </Flex>
        </MYATMCard>
      </Container>

      <LineDivider />
      <Stack name="wallet-options">
        <p className="this-title">What do you want to do?</p>
        <Tabs>
          <Flex
            direction={'column'}
            className={activeTab === 1 ? 'active' : ''}
            onClick={() => toggleTab(1, <Deposit />)}
          >
            <DepositIcon />
            <p>Deposit</p>
          </Flex>
          <Flex
            direction={'column'}
            className={activeTab === 2 ? 'active' : ''}
            onClick={() => toggleTab(2, <TransactionHistory />)}
          >
            <TransactionHistoryIcon />
            <p>Transaction History</p>
          </Flex>
          <Flex
            direction={'column'}
            className={activeTab === 3 ? 'active' : ''}
            onClick={() => toggleTab(3, <Withdraw />)}
          >
            <WithdrawalIcon />
            <p>Withdraw</p>
          </Flex>
        </Tabs>
      </Stack>
      <Container className="overflowY">
        <Flex>{activeTabComponent}</Flex>
      </Container>
    </StyledWallet>
  );
}

export default WalletPreview;
