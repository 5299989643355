import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* ========== Icons ========== */
import { ReactComponent as VideoIcon } from 'assets/icons/svg/video_icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/svg/play_icon.svg';
import { ReactComponent as EventIcon } from 'assets/icons/svg/event_icon.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/svg/more_horizon_icon.svg';

/* ========== Preview Components ========== */
import MyEventsPreview from 'structures/components/SidePane/Events/MyEvents';

/* ========== Styles ========== */
import { StyledAside } from './Styles/Aside.style';

/* ========== Contexts ========== */
import LayoutContext from 'contexts/LayoutContext';
import AuthContext from 'providers/AuthProvider';

function Aside() {
  const { setPreviewWithOverlay } = useContext(LayoutContext);
  const { auth, handleLogout } = useContext(AuthContext);

  /* ========== Function ========== */

  function Logout() {
    setPreviewWithOverlay('', false);
    handleLogout();
  }

  function toggleMoreModal() {
    const moreModal = document.querySelector('.more__modal');
    moreModal.classList.toggle('closed');
  }

  /* ========== New Component ========== */
  return (
    auth && (
      <StyledAside>
        <nav className="aside__nav">
          <Link to={'/'} className="aside__nav--item active">
            <div className="svgContainer">
              <EventIcon />
            </div>
            <p>Events</p>
          </Link>

          <Link
            to={'/live'}
            className="aside__nav--item primary-action"
            onClick={() => setPreviewWithOverlay(<MyEventsPreview />, true)}
          >
            <VideoIcon />
          </Link>

          <Link to={'/play'} className="aside__nav--item">
            <div className="svgContainer play">
              <PlayIcon />
            </div>
            <p>Play</p>
          </Link>
        </nav>

        <div className="svgContainer more" onClick={toggleMoreModal}>
          <MoreIcon />
        </div>

        <div className="more__modal closed">
          <button onClick={toggleMoreModal}>
            <Link to={'/settings'}>Settings</Link>
          </button>
          <button onClick={toggleMoreModal}>Invite friends</button>
          <button onClick={Logout}>Logout</button>
        </div>
      </StyledAside>
    )
  );
}

export default Aside;
