import { Container } from 'structures/layouts/Styles/Container.style';
import React from 'react';

function Videos() {
  return (
    <Container>
      <h3 className="SidePane__title">Videos</h3>
    </Container>
  );
}

export default Videos;
